"use client";

import classNames from "classnames/bind";
import { removeUtmParams } from "../../../helpers/removeUtmParams";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import {
  ExplodedWordsToSpans,
  waitForScrollConfig,
} from "../interactive-hero/utils/interactive-hero-utils";
import { dataLayer } from "../../../helpers/dataLayer";
import {
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { WorbRailItem } from "../../../__generated__/crepo-types";
import { useAnalyticsTrackingState } from "../../../helpers/useAnalyticsTrackingState";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { SliderButton } from "../../slider-button/slider-button";
import useEmblaCarousel from "embla-carousel-react";
import { env } from "../../../helpers/env";
import styles from "./worb-rail.module.css";
import { useLocaleStringContext } from "../../../helpers/LocaleStringContext";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import { notNullish } from "../../../helpers/notNullish";
import {
  isImage,
  isMediabaseDeliveryAgentClips,
  isPreviewClip,
} from "../../../helpers/typeguards";
import { getRbImageUrl } from "../../../helpers/images/getRbImageUrl";
import { CosmosLinkCard } from "@cosmos/web/react";

const ANALYTICS_MAXIMUM_CARDS = 3;
const cx = classNames.bind(styles);

export const WorbRail = ({
  dotcomContent,
  title = "",
  animated,
}: {
  dotcomContent: WorbRailItem[];
  title?: string | null;
  animated?: boolean;
}) => {
  const localeString = useLocaleStringContext();
  const textDirection = useTextDirectionContext();

  const { ref, isIntersecting } = useObserveElementRef<HTMLDivElement>({
    threshold: 0,
  });

  const analyticsTrackingState = useAnalyticsTrackingState();

  useEffect(() => {
    if (
      env.NEXT_PUBLIC_HAS_GTM_CONTAINER &&
      isIntersecting &&
      dotcomContent.length > 0
    ) {
      const cards = dotcomContent.slice(0, ANALYTICS_MAXIMUM_CARDS);

      if (
        !analyticsTrackingState?.some(
          (item) =>
            item.id === cards.map((card) => card.id).join(", ") &&
            item.event === "moduleImpression",
        )
      ) {
        analyticsTrackingState.push({
          id: cards.map((card) => card.id).join(", "),
          event: "moduleImpression",
        });

        analyticsImpression({
          activeCards: cards,
          cards: dotcomContent,
          localeString,
        });
      }
    }
  }, [isIntersecting, dotcomContent, analyticsTrackingState, localeString]);

  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = ref.current;

      const titleWordsElements = titleRef.current?.querySelectorAll("span");
      const contentElement = contentRef.current;
      const timeline = gsap.timeline();
      timeline.from(titleWordsElements ?? null, {
        duration: 0.4,
        y: "+=60%",
        opacity: 0,
        stagger: 0.1,
        clearProps: "all",
      });
      timeline.from(
        contentElement ?? null,
        { duration: 0.6, y: "+=100", opacity: 0 },
        0.5,
      );
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();
      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated, ref]);

  // Embla Options
  const EMBLA_OPTIONS: EmblaOptionsType = {
    align: "start",
    containScroll: "keepSnaps",
    direction: textDirection,
    loop: false,
    watchDrag: true,
  };

  // Embla Carousel
  const [emblaRef, emblaApi] = useEmblaCarousel(EMBLA_OPTIONS);

  // Embla Next/Prev buttons
  const [prevBtnDisabled, setPrevBtnDisabled] = useState<boolean>(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(true);

  // Embla Prev button
  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  // Embla Next button
  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  // Embla onSelect
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  // Embla useEffect
  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const titleId = useId();

  const { ref: intersectionRef, hasIntersected } =
    useObserveElementRef<HTMLDivElement>({
      threshold: 0,
    });
  /**
   * You might ask - isn't this redundant? Isn't this the whole point of the
   * "loading=lazy" attribute?
   *
   * The issue is carousels might be scrolled *vertically* into the viewport,
   * but images that are *horizontally* positioned offscreen will still not
   * load until they're moved into view by interacting with the carousel.
   */
  const imageLoading = hasIntersected ? "eager" : "lazy";

  return (
    <div
      className={cx("container", {
        "container--hidden": !dotcomContent.length,
      })}
      ref={ref}
      role="group"
      aria-labelledby={titleId}
      aria-roledescription="carousel"
    >
      <div className={cx("inner")} ref={intersectionRef}>
        <div className={cx("main")}>
          <div className={cx("header")}>
            <h2 id={titleId} ref={titleRef} className={cx("title")}>
              {animated ? <ExplodedWordsToSpans text={title} /> : title}
            </h2>
            <div
              className={cx("slider-button-wrapper")}
              role="group"
              aria-label="Slide controls"
            >
              <SliderButton
                kind="previous"
                className={cx("slider-button")}
                onClick={onPrevButtonClick}
                disabled={prevBtnDisabled}
              />
              <SliderButton
                kind="next"
                className={cx("slider-button")}
                onClick={onNextButtonClick}
                disabled={nextBtnDisabled}
              />
            </div>
          </div>
          <div className={cx("viewport")}>
            <div className={cx("inner")}>
              <div className={cx("embla")} dir={textDirection}>
                <div className={cx("embla__viewport")} ref={emblaRef}>
                  <div ref={contentRef} className={cx("embla__container")}>
                    {dotcomContent.map((card, index, originalArray) => {
                      const video = card.featuredMedia
                        ?.filter(notNullish)
                        .filter(isPreviewClip)
                        .map((previewClip) => previewClip.clipEssence)
                        .filter(notNullish)
                        .find(isMediabaseDeliveryAgentClips)
                        ?.renditions?.filter(notNullish)
                        .find((rendition) => rendition.ratioTextual === "9x16");

                      const image = card.featuredMedia
                        ?.filter(notNullish)
                        .find(isImage);
                      const imageUrl =
                        (image?.imageEssence?.__typename ===
                          "CloudinaryImage" &&
                          image.imageEssence?.imageURL &&
                          getRbImageUrl(
                            image.imageEssence?.imageURL,
                            {
                              width: 770,
                              height: 578,
                              g: "auto",
                            },
                            localeString,
                          )) ||
                        image?.imageSrc;

                      const logoImage = card.logo?.filter(notNullish)[0];
                      const logoImageUrl =
                        logoImage?.imageEssence?.__typename ===
                        "CloudinaryImage"
                          ? logoImage.imageEssence?.imageURL.replace(
                              "{op}",
                              "w_309,c_fill,f_auto,e_trim:10:transparent",
                            )
                          : logoImage?.imageSrc?.replace(
                              "w_150,h_100",
                              `w_309`,
                            ) ?? undefined;

                      const disciplines =
                        card.disciplines
                          ?.map((discipline) => discipline?.title?.text)
                          .filter(notNullish)
                          .join(", ") || undefined;

                      return (
                        <div
                          key={index}
                          className={cx("embla__slide")}
                          role="group"
                          aria-label={`Slide ${index + 1} of ${
                            originalArray.length
                          }`}
                        >
                          <CosmosLinkCard
                            imageLoading={imageLoading}
                            id={card.id}
                            href={card.url?.href ?? "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                            image={imageUrl ?? ""}
                            video={video?.clipURL ?? undefined}
                            tag={disciplines}
                            logo={logoImageUrl}
                            headline={card.title?.text ?? ""}
                            text={card.subHeading?.text ?? undefined}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getCardLink(
  data: WorbRailItem,
  localeString: string,
  section?: string,
): string {
  if (data.url && data.url.href) {
    return data.url.href;
  }
  return data.uriSlug
    ? `https://www.redbull.com/${localeString}/${section ? `${section}/` : ""}${
        data.uriSlug
      }`
    : "#";
}

function analyticsImpression({
  activeCards,
  cards,
  localeString,
}: {
  activeCards: WorbRailItem[];
  cards: WorbRailItem[];
  localeString: string;
}) {
  const itemsAsPromotions = activeCards.map((activeCard) => {
    let cardLink = "";
    let cardType = "";

    switch (activeCard.type) {
      case "rrn:content:stories":
        cardLink = getCardLink(activeCard, localeString);
        cardType = "Stories";
        break;
      case "rrn:content:externals":
      case "rrn:content:project-profiles":
      case "rrn:content:event-profiles":
        cardLink = getCardLink(activeCard, localeString, "projects");
        cardType = "Externals";
        break;
      case "rrn:content:person-profiles":
      case "rrn:content:team-profiles":
        cardLink = getCardLink(activeCard, localeString, "athlete");
        cardType = "Video";
        break;
      case "rrn:content:videos":
      case "rrn:content:videos-360":
        cardLink = getCardLink(activeCard, localeString, "athlete");
        cardType = "Video";
        break;
      case "rrn:content:films":
      case "rrn:content:movies":
        cardLink = getCardLink(activeCard, localeString, "athlete");
        cardType = "Stories";
        break;
      default:
        cardLink = "";
        cardType = "";
    }

    return {
      id: activeCard.id,
      name: removeUtmParams(cardLink),
      creative: cardType,
      position: cards.findIndex((card) => card.id === activeCard.id) + 1,
    };
  });

  return dataLayer.push({
    event: "moduleImpression",
    availableElements: cards.length,
    promotionmodule: "feed-cards-rail-panel_custom_detachedarrownav",
    ecommerce: {
      promoView: {
        promotions: itemsAsPromotions,
      },
    },
  });
}
