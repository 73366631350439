"use client";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";
import classNames from "classnames/bind";
import template from "../../../helpers/template";
import { removeUtmParams } from "../../../helpers/removeUtmParams";
import { CosmosButton } from "@cosmos/web/react";
import { WorbVideo } from "../../worb-video/worb-video";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { BlocksWorbCarousel } from "../../../__generated__/contentful-types";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Translations } from "../../../helpers/data/getTranslations";
import { dataLayer } from "../../../helpers/dataLayer";
import { WorbCarouselItem } from "../../../__generated__/crepo-types";
import { useViewportDimensions } from "../../../helpers/useViewportDimensions";
import { useAnalyticsTrackingState } from "../../../helpers/useAnalyticsTrackingState";
import { waitForScrollConfig } from "../interactive-hero/utils/interactive-hero-utils";
import { notNullish } from "../../../helpers/notNullish";
import { env } from "../../../helpers/env";
import styles from "./worb-carousel.module.css";
import { useLocaleStringContext } from "../../../helpers/LocaleStringContext";

const cx = classNames.bind(styles);

export interface WorbCarouselProps {
  block: BlocksWorbCarousel;
  dotcomContent: WorbCarouselItem[];
  animated?: boolean;
}

export const WorbCarousel = ({
  block,
  dotcomContent,
  animated,
}: WorbCarouselProps) => {
  const translations = useTranslationsContext();
  const localeString = useLocaleStringContext();

  const { ref, hasIntersected, isIntersecting } =
    useObserveElementRef<HTMLDivElement>({
      threshold: 0,
    });

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const analyticsTrackingState = useAnalyticsTrackingState();

  const { viewportWidth } = useViewportDimensions();

  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (!animated) {
      return;
    }
    const ctx = gsap.context(() => {
      const rootElement = ref.current;
      const titleElement = titleRef.current;
      const containerElement = containerRef.current;
      const timeline = gsap.timeline();
      timeline.from(titleElement ?? null, {
        duration: 0.4,
        y: "+=60%",
        opacity: 0,
        stagger: 0.1,
        clearProps: "all",
      });
      timeline.from(
        containerElement ?? null,
        { duration: 0.6, y: "+=100", opacity: 0 },
        0.5,
      );
      timeline.pause();
      const scrollTrigger = new ScrollTrigger({
        trigger: rootElement,
        start: "top 70%",
        animation: timeline,
        once: true,
      });
      scrollTrigger.disable();
      waitForScrollConfig().then(() => {
        scrollTrigger.enable();
      });
    });

    return () => ctx.revert();
  }, [animated, ref]);

  const timelineWrapperRef = useRef<HTMLDivElement | null>(null);
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const firstTimelineItemRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    const timelineWrapper = timelineWrapperRef.current;
    const timeline = timelineRef.current;
    const firstTimelineItem = firstTimelineItemRef.current;

    if (!(timelineWrapper && timeline && firstTimelineItem)) return;

    /**
     * This animation depends on all item widths being equal. Therefore, it's
     * safe to assume that the width of the first timeline item is
     * representative for *all* items.
     */
    const itemWidth = firstTimelineItem.getBoundingClientRect().width;

    const itemsThatCanFitMinusOne =
      Math.floor(timelineWrapper.getBoundingClientRect().width / itemWidth) - 1;

    const timelineOffset =
      Math.max(activeIndex - itemsThatCanFitMinusOne, 0) * -itemWidth; // px

    timeline.style.setProperty("transform", `translateX(${timelineOffset}px)`);
    /**
     * When we get to the end of the timeline, we want to "hop" back
     * to the beginning without a transition.
     */
    timeline.style.setProperty(
      "transition-duration",
      activeIndex === 0 ? "0ms" : "500ms",
    );
  }, [activeIndex]);

  return (
    <div className={cx("container")} ref={ref}>
      <div className={cx("header")}>
        <div className={cx("header-inner")}>
          <h2 ref={titleRef} className={cx("title")}>
            {block.title}
          </h2>
        </div>
      </div>
      <div ref={containerRef} className={cx("inner")}>
        {dotcomContent && (
          <Fragment>
            <div
              className={cx("navigation-overlay", "navigation-overlay--left")}
              onClick={() => {
                if (activeIndex !== 0) {
                  setActiveIndex((current) => current - 1);
                }
              }}
            />
            <div
              className={cx("navigation-overlay", "navigation-overlay--right")}
              onClick={() => {
                if (activeIndex !== dotcomContent.length - 1) {
                  setActiveIndex((current) => current + 1);
                }
              }}
            />
          </Fragment>
        )}
        {dotcomContent && (
          <div className={cx("timeline-wrapper")} ref={timelineWrapperRef}>
            <div className={cx("timeline")} ref={timelineRef}>
              {dotcomContent.map((timelineItem, index) => (
                <a
                  key={index}
                  className={cx("timeline-item", {
                    "timeline-item--active":
                      isIntersecting && activeIndex === index,
                  })}
                  ref={index === 0 ? firstTimelineItemRef : undefined}
                  onClick={() => {
                    if (
                      env.NEXT_PUBLIC_HAS_GTM_CONTAINER &&
                      !analyticsTrackingState.some(
                        (item) =>
                          item.id === timelineItem.id &&
                          item.event === "moduleClick",
                      )
                    ) {
                      analyticsTrackingState.push({
                        id: timelineItem.id,
                        event: "moduleClick",
                      });

                      analyticsClick({
                        id: timelineItem.id,
                        itemTotal: dotcomContent.length,
                        itemLink: getCardLink(timelineItem, localeString),
                        itemType: timelineItem.type,
                        itemIndex: index + 1,
                      });
                    }

                    setActiveIndex(index);
                  }}
                >
                  <div className={cx("timeline-progress-bar")}>
                    <div
                      className={cx("timeline-progress-bar-meter")}
                      onAnimationStart={() => {
                        if (
                          env.NEXT_PUBLIC_HAS_GTM_CONTAINER &&
                          isIntersecting &&
                          !analyticsTrackingState.some(
                            (item) =>
                              (item.id === timelineItem.id &&
                                item.event === "moduleImpression") ||
                              (item.id === timelineItem.id &&
                                item.event === "moduleClick"),
                          )
                        ) {
                          analyticsTrackingState.push({
                            id: timelineItem.id,
                            event: "moduleImpression",
                          });

                          analyticsImpression({
                            id: timelineItem.id,
                            itemTotal: dotcomContent.length,
                            itemLink: getCardLink(timelineItem, localeString),
                            itemType: timelineItem.type,
                            itemIndex: index + 1,
                          });
                        }
                      }}
                      onAnimationEnd={() => {
                        setActiveIndex(
                          dotcomContent[index + 1] ? index + 1 : 0,
                        );
                      }}
                    />
                  </div>
                  <span className={cx("timeline-tag")}>
                    {timelineItem.contextualTitle?.featured?.text ||
                      timelineItem.title?.text}
                  </span>
                </a>
              ))}
            </div>
          </div>
        )}
        {dotcomContent &&
          dotcomContent.map((timelineItem, index) => (
            <div
              key={`${timelineItem.id && timelineItem.id}-${
                block.sys.id
              }-${index}`}
              className={cx("content", {
                "content--hidden": activeIndex !== index,
              })}
            >
              <WorbVideo
                item={timelineItem}
                viewportWidth={viewportWidth}
                className={cx("video")}
                imageClassName={cx("image")}
                hasIntersected={hasIntersected}
              />
              <div className={cx("overlay")} />
              <div className={cx("content-text")}>
                <span className={cx("content-tag")}>
                  {timelineItem.disciplines?.filter(notNullish)[0]?.title?.text}
                </span>
                <h3 className={cx("content-title")}>
                  {timelineItem.contextualTitle?.featured?.text ||
                    timelineItem.title?.text}
                </h3>
                <CosmosButton
                  href={getCardLink(timelineItem, localeString)}
                  onClick={() => {
                    if (env.NEXT_PUBLIC_HAS_GTM_CONTAINER) {
                      analyticsClick({
                        id: timelineItem.id,
                        itemTotal: dotcomContent.length,
                        itemLink: getCardLink(timelineItem, localeString),
                        itemType: timelineItem.type,
                        itemIndex: index + 1,
                      });
                    }
                  }}
                  kind="primary"
                  size="large"
                  className={cx("content-button")}
                >
                  {getButtonTranslation(timelineItem, translations)}
                </CosmosButton>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

function getCardLink(data: WorbCarouselItem, localeString: string): string {
  const sectionTypes = [
    { type: "rrn:content:person-profiles", section: "athletes" },
    { type: "rrn:content:event-profiles", section: "events" },
    { type: "rrn:content:project-profiles", section: "projects" },
  ];

  const section = sectionTypes.find((item) => item.type === data.type)?.section;

  if (data.url && data.url.href) {
    return data.url.href;
  }
  return data.uriSlug
    ? `https://www.redbull.com/${localeString}/${section ? `${section}/` : ""}${
        data.uriSlug
      }`
    : "#";
}

function getButtonTranslation(
  item: WorbCarouselItem,
  translations: Translations,
): string | null {
  switch (item.type) {
    case "rrn:content:videos":
      return template(
        translations["panoptikum.button.label.video"],
        "Watch Video",
      );
    case "rrn:content:videos-360":
      return template(
        translations["panoptikum.button.label.video360"],
        "Watch Video",
      );
    case "rrn:content:live-videos":
      return template(
        translations["panoptikum.button.label.videoReplay"],
        "Watch Replay",
      );
    case "rrn:content:recap-videos":
      return template(
        translations["panoptikum.button.label.videoRecap"],
        "Watch Recap",
      );
    case "rrn:content:trailer-videos":
      return template(
        translations["panoptikum.button.label.videoTrailer"],
        "Watch Trailer",
      );
    case "rrn:content:stories":
      return template(
        translations["panoptikum.button.label.story"],
        "Read Story",
      );
    case "rrn:content:image-galleries":
      return template(
        translations["panoptikum.button.label.imageGallery"],
        "View Gallery",
      );
    case "rrn:content:person-profiles":
      return template(
        translations["panoptikum.button.label.person"],
        "View Profile",
      );
    case "rrn:content:event-profiles":
      return template(
        translations["panoptikum.button.label.event"],
        "View Event Info",
      );
    case "rrn:content:project-profiles":
      return template(
        translations["panoptikum.button.label.project"],
        "More Info",
      );
    case "rrn:content:films":
      return template(
        translations["panoptikum.button.label.film"],
        "Watch Film",
      );
    case "rrn:content:shows":
      return template(
        translations["panoptikum.button.label.show"],
        "View All Episodes",
      );
    case "rrn:content:externals":
      return template(
        translations["panoptikum.button.label.external"],
        "Take Me There",
      );
    case "rrn:content:episode-videos":
      return template(
        translations["panoptikum.button.label.episode"],
        "Watch Episode",
      );
    case "rrn:content:event-series":
      return template(
        translations["panoptikum.button.label.eventseries"],
        "View Event Calendar",
      );
    default:
      return null;
  }
}

function analyticsImpression({
  id,
  itemTotal,
  itemLink,
  itemType,
  itemIndex,
}: {
  id: string;
  itemTotal: number;
  itemLink: string;
  itemType: string;
  itemIndex: number;
}) {
  if (!id || !itemTotal || !itemLink || !itemType || !itemIndex) {
    return null;
  }

  return dataLayer.push({
    event: "moduleImpression",
    availableElements: itemTotal,
    promotionmodule: "panoptikum_custom_topnav",
    ecommerce: {
      promoView: {
        promotions: [
          {
            id,
            name: removeUtmParams(itemLink),
            creative: itemType,
            position: itemIndex,
          },
        ],
      },
    },
  });
}

function analyticsClick({
  id,
  itemTotal,
  itemLink,
  itemType,
  itemIndex,
}: {
  id: string;
  itemTotal: number;
  itemLink: string;
  itemType: string;
  itemIndex: number;
}) {
  if (!id || !itemTotal || !itemLink || !itemType || !itemIndex) {
    return null;
  }

  return dataLayer.push({
    event: "moduleClick",
    availableElements: itemTotal,
    promotionmodule: "panoptikum_custom_topnav",
    promotionclicktype: "Text",
    ecommerce: {
      promoClick: {
        promotions: [
          {
            id,
            name: removeUtmParams(itemLink),
            creative: itemType,
            position: itemIndex,
          },
        ],
      },
    },
  });
}
